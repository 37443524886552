import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Grafitos } from "../modelos/grafitos";
import { GrafitosService } from "../servicios/grafitos.service";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-listargrafitos',
  templateUrl: './listargrafitos.component.html',
  styleUrls: ['./listargrafitos.component.css'],
  providers: [GrafitosService]
})
export class ListargrafitosComponent implements OnInit {
  public ID: string;
  public nombre: string;
  public idTipoIcono: number;
  public idCategoria: number;
  public idProyecto: number;
  public palabrasClave: number;
  public idMaterial: number;
  public idTecnica: number;
  public idPais: number;
  public idSitio: number;
  public idArea: number;
  public idPosicion: number;
  public idUbicacion: number;
  public idSituacion: number;
  public grafitos: Grafitos;
  public titulo: string;
  public ruta: string;
  public noImagen: string;
  config: any;
  collection = { count: 60, data: [] };

  constructor(
    private _grafitosService: GrafitosService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.titulo="Resultado Búsqueda Grafitos";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
    this.config = {
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: this.grafitos
    };
  }

  pageChanged(event){
    this.config.currentPage = event;
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    /* Recibimos los parámetros enviados por el formulario del buscador 
    */

    this.route.queryParams.subscribe(params => {
      this.ID = (params['ID']); 
      this.nombre = (params['nombre']); 
      this.idTipoIcono = (params['idTipoIcono']); 
      this.idCategoria = (params['idCategoria']);  
      this.idProyecto = (params['idProyecto']);  
      this.palabrasClave = (params['palabrasClave']);  
      this.idMaterial = (params['idMaterial']);  
      this.idTecnica = (params['idTecnica']);  
      this.idPais = (params['idPais']);  
      this.idSitio = (params['idSitio']);
      this.idArea = (params['idArea']); 
      this.idPosicion = (params['idPosicion']); 
      this.idUbicacion = (params['idUbicacion']); 
      this.idSituacion = (params['idSituacion']); 
      });

      console.log("recibo listado tipoicono");
      console.log(this.idTipoIcono);

      this.listarbusqueda();
  }

  listarbusqueda(){
    console.log(this.idTipoIcono);
    /* carga el servicio para datatable de angular material */
    /* carga el servicio para html */
      
    this._grafitosService.getGrafitosFront(
      this.ID,
      this.nombre,
      this.idTipoIcono, 
      this.idCategoria,  
      this.idProyecto,
      this.palabrasClave, 
      this.idMaterial, 
      this.idTecnica,  
      this.idPais, 
      this.idSitio,
      this.idArea,
      this.idPosicion,
      this.idUbicacion,
      this.idSituacion
    ).subscribe(
      result => {
        if(result.code != 200){
            console.log(result);
        }else{
            this.grafitos = result.data;
        }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

}
