import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Posiciones } from '../modelos/posiciones';
import { GLOBAL } from './global';

@Injectable()
export class PosicionesService {
  public url: string;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  getPosiciones(): Observable<any>{
    return this.http.get(this.url+'listarposiciones');
  }

  getPosicionesselect(): Observable<any>{
    return this.http.get(this.url+'listarposicionesselect');
  }

  addPosicion(posiciones: Posiciones): Observable<any>{
    let json = JSON.stringify(posiciones);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'crearposicion', params, {headers: headers});
  }

  delPosicion(id): Observable<any>{
    return this.http.get(this.url+'borrarposicion/'+id);
  }

  getPosicion(id): Observable<any>{
    return this.http.get(this.url+'leerposicion/'+id);
  }

  updatePosicion(id, Posiciones: Posiciones): Observable<any>{
    let json = JSON.stringify(Posiciones);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'modificarposicion/'+id, params, {headers: headers});
  }

	makeFileRequest(url: string, params: Array<string>, files: Array<File>){
		return new Promise((resolve, reject)=>{
			var formData: any = new FormData();
			var xhr = new XMLHttpRequest();

			for(var i = 0; i < files.length; i++){
				formData.append('uploads[]', files[i], files[i].name);
			}

			xhr.onreadystatechange = function(){
				if(xhr.readyState == 4){
					if(xhr.status == 200){
						resolve(JSON.parse(xhr.response));
					}else{
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', url, true);
			xhr.send(formData);
		});
	}
}