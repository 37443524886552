import { Component, OnInit } from '@angular/core';
import { Tecnicas } from "../modelos/tecnicas";
import { TecnicasService } from "../servicios/tecnicas.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-tecnicas',
  templateUrl: './tecnicas.component.html',
  styleUrls: ['./tecnicas.component.css'],
  providers: [TecnicasService]
})
export class TecnicasComponent implements OnInit {
  public tecnicas: Tecnicas;
  public titulo: string;
  public ruta: string;
  public noImagen: string;

  constructor(
    private _tecnicasService: TecnicasService,
    private router: Router
  ) { 
    this.titulo = "Tecnicas";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
  }

  ngOnInit() {
    this.listartecnicas();
  }

  listartecnicas(){
    /* carga el servicio para html */
    this._tecnicasService.getTecnicas().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.tecnicas = result.data;
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

}
