import { Component, OnInit } from '@angular/core';
import { Tiposiconograficos } from "../modelos/tiposiconograficos";
import { TiposiconograficosService } from "../servicios/tiposiconograficos.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-tipoicono',
  templateUrl: './tipoicono.component.html',
  styleUrls: ['./tipoicono.component.css'],
  providers: [TiposiconograficosService]
})
export class TipoiconoComponent implements OnInit {
  public tiposiconos: Tiposiconograficos;
  public titulo: string;
  public ruta: string;
  public noImagen: string;

  constructor(
    private _tipoiconoService: TiposiconograficosService,
    private router: Router
  ) { 
    this.titulo = "Categorías";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
  }

  ngOnInit() {
    this.listartiposiconograficos();
  }

  // Saca el listado de todas las categorías
  listartiposiconograficos(){
    /* carga el servicio para html */
    this._tipoiconoService.getTiposiconograficos().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.tiposiconos = result.data;
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

}
