import { Component, OnInit } from '@angular/core';
import { Secciones } from '../modelos/secciones';
import { SeccionesService } from "../servicios/secciones.service";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css'],
  providers: [SeccionesService]
})
export class CookiesComponent implements OnInit {
  public titulo: string;
  public seccion: Secciones;
  public ruta: string;

  constructor(
    private _seccionesService: SeccionesService
  ) { 
    this.titulo = "Política de Cookies";
    this.seccion = new Secciones(null, "", "", "", null, null, "", "");
    this.ruta=GLOBAL.urlImagenes;
  }

  ngOnInit() {
    this.leerSeccion();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  leerSeccion() {

    let id=8;

    this._seccionesService.getSeccion(id).subscribe(
      result => {
          if(result.code == 200){
            console.log(result);
            this.seccion = result.data;
          }else{
            alert("Error al leer registro");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

}
