import { Component, OnInit } from '@angular/core';
import { Grafitos } from '../modelos/grafitos';
import { Fotografias } from '../modelos/fotografias';
import { GrafitosService } from "../servicios/grafitos.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import { GLOBAL } from "../servicios/global";
import { FotografiasService } from '../servicios/fotografias.service';


@Component({
  selector: 'app-detallegrafito',
  templateUrl: './detallegrafito.component.html',
  styleUrls: ['./detallegrafito.component.css'],
  providers: [GrafitosService, FotografiasService]
})
export class DetallegrafitoComponent implements OnInit {
  public grafito: Grafitos;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado
  //datos de la busqueda
  public bID: number;
  public bnombre: string;
  public bidTipoIcono: number;
  public bidCategoria: number;
  public bidProyecto: number;
  public bpalabrasClave: string;
  public bidMaterial: number;
  public bidTecnica: number;
  public bidPais: number;
  public bidSitio: number;
  public bidArea: number;
  public bidPosicion: number;
  public bidUbicacion: number;
  public bidSituacion: number;
  public imagenesGrafitos: any[] = [];
  public grafitoImg: string;

  constructor(
    private _grafitosService: GrafitosService,
    private _fotografiasService: FotografiasService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.grafito = new Grafitos(null, "", "", "", "", "", "", null, null, "", "", null, null, null, null, "", null, null, "", "", "", null, "", "", null, null, "", null, "", "", "", null, "", null, null, "", "", "");
    this.titulo = "Detalle del Grafito";
    this.ruta = GLOBAL.urlImagenes;
    this.respuesta = 0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta = null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    this.leerGrafito();
  }

  volver() {
    //Para hacer la subida del fichero
    console.log('detalle envio tipo icono');
    console.log(this.bidTipoIcono);
    console.log('****************');

    this.router.navigate(['/listargrafitos'], {
      queryParams: {
        ID: this.bID,
        nombre: this.bnombre,
        idTipoIcono: this.bidTipoIcono,
        idCategoria: this.bidCategoria,
        idProyecto: this.bidProyecto,
        palabrasClave: this.bpalabrasClave,
        idMaterial: this.bidMaterial,
        idTecnica: this.bidTecnica,
        idPais: this.bidPais,
        idSitio: this.bidSitio,
        idArea: this.bidArea,
        idPosicion: this.bidPosicion,
        idUbicacion: this.bidUbicacion,
        idSituacion: this.bidSituacion
      }
    });
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  leerGrafito() {
    this.route.queryParams.subscribe(params => {
      this.bID = (params['ID']);
      this.bnombre = (params['nombre']);
      this.bidTipoIcono = (params['idTipoIcono']);
      this.bidCategoria = (params['idCategoria']);
      this.bidProyecto = (params['idProyecto']);
      this.bpalabrasClave = (params['palabrasClave']);
      this.bidMaterial = (params['idMaterial']);
      this.bidTecnica = (params['idTecnica']);
      this.bidPais = (params['idPais']);
      this.bidSitio = (params['idSitio']);
      this.bidArea = (params['idArea']);
      this.bidPosicion = (params['idPosicion']);
      this.bidUbicacion = (params['idUbicacion']);
      this.bidSituacion = (params['idSituacion']);
    });

    console.log("detalle recibo tipo icono");
    console.log(this.bID);
    console.log(this.bnombre);
    console.log(this.bidTipoIcono);
    console.log(this.bidCategoria);
    console.log(this.bidProyecto);
    console.log(this.bpalabrasClave);
    console.log(this.bidMaterial);
    console.log(this.bidTecnica);
    console.log(this.bidPais);
    console.log(this.bidSitio);
    console.log(this.bidArea);
    console.log(this.bidPosicion);
    console.log(this.bidUbicacion);
    console.log(this.bidSituacion);
    console.log("-------------------");

    this.route.params.forEach((params: Params) => {
      let id = params['id'];

      this._grafitosService.getGrafito(id).subscribe(
        result => {
          if (result.code == 200) {
            console.log(result);
            this.grafito = result.data;
            this.cargarFotosGrafito();
          } else {
            alert("Error al leer registro");
            console.log(result);
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    });
  }

  cargarFotosGrafito() {
    this._fotografiasService.getFotografiasByGrafitoId(this.grafito.idGrafito).subscribe(
      result => {
        this.imagenesGrafitos = result.data;
        console.log('?????????? -- ', result);
      }
    );
    this._fotografiasService.getFotografias().subscribe(
      (result) => {
        // this.imagenesGrafitos = result.data;
        console.log('!!!!!!!!!! -- ', this.imagenesGrafitos);
        
      }
    );
  }

  setImagenGrafito(imagen){
    this.grafitoImg = imagen;
  }

}

