import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from "./home/home.component";
import { TipoiconoComponent } from "./tipoicono/tipoicono.component";
import { CategoriasComponent } from "./categorias/categorias.component";
import { TecnicasComponent } from "./tecnicas/tecnicas.component";
import { BuscadorComponent } from "./buscador/buscador.component";
import { ListargrafitosComponent } from "./listargrafitos/listargrafitos.component";
import { DetallegrafitoComponent } from "./detallegrafito/detallegrafito.component";
import { ContactoComponent } from "./contacto/contacto.component";
import { PoliticaComponent } from "./politica/politica.component";
import { CookiesComponent } from "./cookies/cookies.component";
// importar componente 404
import { Pagina404Component } from "./pagina404/pagina404.component";


const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "tiposiconograficos", component: TipoiconoComponent },
  { path: "categorias/:id", component: CategoriasComponent },
  { path: "tecnicas", component: TecnicasComponent },
  { path: "buscador", component: BuscadorComponent },
  { path: "listargrafitos", component: ListargrafitosComponent },
  { path: "detallegrafito/:id", component: DetallegrafitoComponent },
  { path: "contacto", component: ContactoComponent },
  { path: "politica", component: PoliticaComponent },
  { path: "cookies", component: CookiesComponent },

  { path: "404", component: Pagina404Component },
  { path: "**", component: Pagina404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
