import { Component, OnInit } from '@angular/core';
import { Categorias } from "../modelos/categorias";
import { CategoriasService } from "../servicios/categorias.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css'],
  providers: [CategoriasService]
})
export class CategoriasComponent implements OnInit {
  public categorias: Categorias;
  public confirmado:number;
  public titulo: string;
  public ruta: string;
  public noImagen: string;

  constructor(
    private _categoriaService: CategoriasService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.titulo = "Categorías";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
  }

  ngOnInit() {
    this.listarcategorias();
  }

  // Saca el listado de todas las categorías
  listarcategorias(){

    this.route.params.forEach((params: Params) => {
      let id=params['id'];
    
    /* carga el servicio para html */
    this._categoriaService.getCategoriasportipo(id).subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.categorias = result.data;
          }
      },
      error => {
          console.log(<any>error);
        }
        );
      });
    }

}
