import { Component, OnInit } from '@angular/core';
import { Secciones } from '../modelos/secciones';
import { SeccionesService } from "../servicios/secciones.service";
import { Contactos } from '../modelos/contactos';
import { ContactosService } from "../servicios/contactos.service";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
  providers: [SeccionesService, ContactosService]
})
export class ContactoComponent implements OnInit {
  public titulo: string;
  public seccion: Secciones;
  public contactos: Contactos;
  public ruta: string;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado
  public validar;

  constructor(
    private _contactosService: ContactosService,
    private _seccionesService: SeccionesService
    ) { 
    this.titulo = "Contacto";
    this.seccion = new Secciones(null, "", "", "", null, null, "", "");
    this.contactos= new Contactos(null,"","","",null);
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    this.leerSeccion();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  leerSeccion() {
    let id=5;
    this._seccionesService.getSeccion(id).subscribe(
      result => {
          if(result.code == 200){
            console.log(result);
            this.seccion = result.data;
          }else{
            alert("Error al leer registro");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');

    this._contactosService.send(this.contactos).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  FieldsChange(values:any){

    if(values.currentTarget.checked==true){
        this.validar=1;
    }else{
        this.validar=0;
    }
  }

}
