import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contactos } from '../modelos/contactos';
import { GLOBAL } from './global';

@Injectable()
export class ContactosService {
  public url: string;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  send(Contactos: Contactos): Observable<any>{
    let json = JSON.stringify(Contactos);
    let params = 'json=' + json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.url+'send', params, {headers: headers});
    }
}
