export class Grafitos {
    constructor(
      public idGrafito: number,
      public ID: string,
      public nombre: string,
      public ncatalogo: string,
      public refProyecto: string,
      public autorFicha: string,
      public fecha: string,
      public idMaterial: number,
      public idTecnica: number,
      public alto: string,
      public ancho: string,
      public idPosicion: number,
      public idSituacion: number,
      public pigmentos: number,
      public idPais: number,
      public estadoDpto: string,
      public idArea: number,
      public idSitio: number,
      public conjunto: string,
      public edificio: string,
      public estancia: string,
      public idUbicacion: number,
      public coordenadax: string,
      public coordenaday: string,
      public idTipoIcono: number,
      public idCategoria: number,
      public palabrasClave: string,
      public idProyecto: number,
      public webinstitucion: string,
      public correoconsulta: string,
      public otrasweb: string,
      public publicado: string,
      public autorCalco: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string,
      public urlImagen: string
    ) {}
  }