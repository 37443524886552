export var GLOBAL = {


    /* DESARROLLO */
    
    // url: 'http://localhost/GrafitosMayasUltimaversion/grafitosmayasadmin/src/bkend/index.php/',
    // urlImagenes: 'http://localhost/GrafitosMayasUltimaversion/grafitosmayasadmin/src/bkend/uploads/',
    // urlNoImagen: 'http://localhost/GrafitosMayasUltimaversion/grafitosmayasadmin/src/assets/camara.png'
    

    /* PRODUCCIÓN */
    
    url: 'https://gestion.artemaya.es/bkend/index.php/',
    urlImagenes: 'https://gestion.artemaya.es/bkend/uploads/',
    urlNoImagen: 'https://gestion.artemaya.es/assets/camara.png'
    

}