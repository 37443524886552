import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Tiposiconograficos } from '../modelos/tiposiconograficos';
import { GLOBAL } from './global';

@Injectable()
export class TiposiconograficosService {
  public url: string;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  getTiposiconograficos(): Observable<any>{
    return this.http.get(this.url+'listartiposiconograficos');
  }


  getTiposiconograficosselect(): Observable<any>{
    return this.http.get(this.url+'listartiposiconograficosselect');
  }

  addTipoiconografico(tiposiconograficos: Tiposiconograficos): Observable<any>{
    let json = JSON.stringify(tiposiconograficos);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'creartipoiconografico', params, {headers: headers});
  }

  delTipoiconografico(id): Observable<any>{
    return this.http.get(this.url+'borrartipoiconografico/'+id);
  }

  getTipoiconografico(id): Observable<any>{
    return this.http.get(this.url+'leertipoiconografico/'+id);
  }

  updateTipoiconografico(id, Tiposiconograficos: Tiposiconograficos): Observable<any>{
    let json = JSON.stringify(Tiposiconograficos);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'modificartipoiconografico/'+id, params, {headers: headers});
  }

	makeFileRequest(url: string, params: Array<string>, files: Array<File>){
		return new Promise((resolve, reject)=>{
			var formData: any = new FormData();
			var xhr = new XMLHttpRequest();

			for(var i = 0; i < files.length; i++){
				formData.append('uploads[]', files[i], files[i].name);
			}

			xhr.onreadystatechange = function(){
				if(xhr.readyState == 4){
					if(xhr.status == 200){
						resolve(JSON.parse(xhr.response));
					}else{
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', url, true);
			xhr.send(formData);
		});
	}
}