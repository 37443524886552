import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Pagina404Component } from './pagina404/pagina404.component';
import { PieComponent } from './pie/pie.component';
import { MenuComponent } from './menu/menu.component';
import { CabeceraComponent } from './cabecera/cabecera.component';
import { ContenidoComponent } from './contenido/contenido.component';
import { HomeComponent } from './home/home.component';
import { PoliticaComponent } from './politica/politica.component';
import { CookiesComponent } from './cookies/cookies.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { TecnicasComponent } from './tecnicas/tecnicas.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { ListargrafitosComponent } from './listargrafitos/listargrafitos.component';
import { DetallegrafitoComponent } from './detallegrafito/detallegrafito.component';
import { ContactoComponent } from './contacto/contacto.component';
import { TipoiconoComponent } from './tipoicono/tipoicono.component';

@NgModule({
  declarations: [
    AppComponent,
    Pagina404Component,
    PieComponent,
    MenuComponent,
    CabeceraComponent,
    ContenidoComponent,
    HomeComponent,
    PoliticaComponent,
    CookiesComponent,
    CategoriasComponent,
    TecnicasComponent,
    BuscadorComponent,
    ListargrafitosComponent,
    DetallegrafitoComponent,
    ContactoComponent,
    TipoiconoComponent,
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    IvyCarouselModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    IvyCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
