import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Fotografias } from '../modelos/fotografias';
import { GLOBAL } from './global';

@Injectable()
export class FotografiasService {
  public url: string;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  getFotografias(): Observable<any>{
    return this.http.get(this.url+'listarfotografias');
  }

  addFotografia(fotografias: Fotografias): Observable<any>{
    let json = JSON.stringify(fotografias);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'crearfotografia', params, {headers: headers});
  }

  delFotografia(id): Observable<any>{
    return this.http.get(this.url+'borrarfotografia/'+id);
  }

  getFotografia(id): Observable<any>{
    return this.http.get(this.url+'leerfotografia/'+id);
  }

  getFotografiasByGrafitoId(id): Observable<any>{
    return this.http.get(this.url+'listarfotografiasbygrafito/'+id);
  }

  updateFotografia(id, Fotografias: Fotografias): Observable<any>{
    let json = JSON.stringify(Fotografias);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'modificarfotografia/'+id, params, {headers: headers});
  }

	makeFileRequest(url: string, params: Array<string>, files: Array<File>){
		return new Promise((resolve, reject)=>{
			var formData: any = new FormData();
			var xhr = new XMLHttpRequest();

			for(var i = 0; i < files.length; i++){
				formData.append('uploads[]', files[i], files[i].name);
			}

			xhr.onreadystatechange = function(){
				if(xhr.readyState == 4){
					if(xhr.status == 200){
						resolve(JSON.parse(xhr.response));
					}else{
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', url, true);
			xhr.send(formData);
		});
	}
}